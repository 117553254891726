<template>
  <div class="edit-ship-images position-relative">
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="bodytext tile">
      <h2>{{ __('shipImages') }}</h2>
      <ul class="futuristic">
        <li>{{__('editShipImagesHint1')}}</li>
        <li>{{__('editShipImagesHint2')}}</li>
        <li>{{__('editShipImagesHint3')}}</li>
      </ul>
    </div>
    <template v-if="shipId !== null">
      <div class="image-selection mt"
           @dragenter="draggedOverFileId = null"
           @dragend="handleDragEnd"
      >
        <div class="image-selection__image"
             :class="{'image-selection__image--dragged-over': dragStarted && draggedOverFileId === shipImage.fileId}"
             :style="getImageStyle(shipImage)"
             v-for="shipImage in sortedShipImages"
             draggable="true"
             @dragenter="handleDragEnter(shipImage,$event)"
             @dragstart="handleDragStart(shipImage)"
             @dragend="handleDragEnd"
        >
          <video controls v-if="shipImage.file.type === 'mp4'" preload="none"
                 :poster="shipImage.file.thumbnailUri">
            <source :src="shipImage.file.uri" type="video/mp4"/>
          </video>
          <div class="image-selection__toolbar">
            <div class="image-selection__toolbar-icon" v-if="getPrependIcon(shipImage)">
              <icon :icon="getPrependIcon(shipImage)"/>
            </div>
            <div class="image-selection__toolbar-spacer"></div>
            <div class="image-selection__toolbar-button"
                 v-if="addAndDeleteAllowed"
                 :title="__('common.delete')"
                 @click="toggleDeleted(shipImage)">
              <icon icon="bin"></icon>
            </div>
          </div>
        </div>
        <div class="image-selection__add"
             v-if="addAndDeleteAllowed"
             :class="{'image-selection__add--dragged-over': dragStarted && draggedOverFileId === null}"
             :title="__('addShipImages')"
             v-slashes
             @click="showFileBrowser()"
        >
          <icon :icon="'add'"/>
        </div>
      </div>
      <div class="layout">
        <div class="flex xs6 mt">
          <div class="btn btn--success" :loading="loading" :disabled="!hasChanges()"
               @click="saveShipImages"
               v-slashes>{{ __('common.save_changes') }}
          </div>
        </div>
      </div>
    </template>
    <p v-else>save first</p>
    <my-dialog :show="fileBrowserShown" :fullscreen="true">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ __('component.auth.select_avatar.title') }}</div>
          <div class="card__button" @click="hideFileBrowser()">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <file-browser :embedded="true"
                        :multiple="true"
                        @multipleFilesSelected="handleFilesSelected"
          />
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import apiSecured from '../../../api/secured';
import { notificationsMixin } from '../../../mixins/notifications';
import { localizationMixin } from '../../../mixins/localization';
import FileBrowser from '../../Files/FileBrowser'

export default {
  components: { FileBrowser },
  mixins: [notificationsMixin, localizationMixin],
  data: () => ({
    loading: false,
    shipImages: [],
    inProgress: 0,
    fileBrowserShown: false,
    draggedOverFileId: null,
    dragStarted: false,
    draggedFileId: null
  }),
  props: ['shipId'],
  watch: {
    shipId() {
      this.refresh();
    }
  },
  computed: {
    sortedShipImages() {
      return this.shipImages.sort((a, b) => a.sorting - b.sorting);
    },
    addAndDeleteAllowed() {
      return !this.shipImages.some(shipImage => this.isChanged(shipImage));
    },
    draggingAllowed() {
      return !this.shipImages.some(shipImage => this.isNew(shipImage) || this.isDeleted(shipImage));
    },
  },
  created() {
    this.refresh();
  },
  methods: {
    handleDragStart(shipImage) {
      if (this.draggingAllowed) {
        this.dragStarted = true;
        this.draggedFileId = shipImage.fileId;
      }
    },
    handleDragEnter(shipImage, event) {
      this.draggedOverFileId = shipImage.fileId;
      event.stopImmediatePropagation();
    },
    handleDragEnd(event) {
      if (!this.dragStarted) {
        return;
      }
      event.stopImmediatePropagation();
      this.dragStarted = false;

      let shipImages = this.sortedShipImages;
      const indexDragged = shipImages.findIndex(shipImage => shipImage.fileId === this.draggedFileId);
      const dragged = shipImages[indexDragged];

      if (this.draggedOverFileId === null) {
        shipImages = shipImages.filter(shipImage => shipImage.fileId !== dragged.fileId);
        shipImages.push(dragged);
      } else {
        shipImages.splice(indexDragged, 1);
        const indexTarged = shipImages.findIndex(shipImage => shipImage.fileId === this.draggedOverFileId);
        shipImages.splice(indexTarged, 0, dragged);
      }

      this.shipImages = shipImages.map((shipImage, index) => {
        shipImage.sorting = index;
        shipImage.changed = true;
        return shipImage;
      });
    },
    getImageStyle(shipImage) {
      return {
        backgroundImage: `url(${shipImage.file.type === 'mp4' ? shipImage.file.thumbnailUri : shipImage.file.uri})`
      }
    },
    hasChanges() {
      return this.shipImages.filter((shipImage) => {
        return this.isNew(shipImage) || this.isChanged(shipImage) || this.isDeleted(shipImage);
      }).length > 0;
    },
    refresh() {
      this.loading = true;
      this.shipImages = [];
      if (this.shipId === null) {
        this.loading = false;
        return;
      }
      const params = {
        orderBy: 'sorting asc'
      }
      apiSecured.get('/ingame/expo/shipimage/by-ship/' + this.shipId, { params }).then(async (res) => {
        this.shipImages = await this.enrichRecords(res.data);
        this.loading = false;
        this.showInfoNotification(this.__('common.data_loaded'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
    async enrichRecords(shipImages) {
      const fileIds = shipImages.map(record => record.fileId);
      if (fileIds.length > 0) {
        await apiSecured.get(`/fal/file/(${fileIds.join(',')})`).then((res) => {
          shipImages = shipImages.map(record => {
            record.file = res.data.find(resRecord => resRecord.id === record.fileId);
            return record;
          });
          this.showInfoNotification('Files have been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
      }
      return shipImages;
    },
    registerChange(shipImage) {
      if (!this.isNew(shipImage)) {
        shipImage.changed = true;
        this.$forceUpdate();
      }
    },
    isNew(shipImage) {
      return shipImage.hasOwnProperty('new') && shipImage.new;
    },
    isChanged(shipImage) {
      return shipImage.hasOwnProperty('changed') && shipImage.changed;
    },
    isDeleted(shipImage) {
      return shipImage.hasOwnProperty('deleted') && shipImage.deleted;
    },
    showFileBrowser() {
      this.fileBrowserShown = true;
    },
    hideFileBrowser() {
      this.fileBrowserShown = false;
    },
    handleFilesSelected(files) {
      files.forEach(file => {
        if (!this.shipImages.some(shipImage => shipImage.fileId === file.id)) {
          this.shipImages.push({
            sorting: this.shipImages.length,
            shipId: this.shipId,
            fileId: file.id,
            file: file,
            new: true
          });
        }
      });
      this.fileBrowserShown = false;
    },
    toggleDeleted(shipImage) {
      if (this.isNew(shipImage)) {
        this.shipImages = this.shipImages.filter((fShipImage) => fShipImage !== shipImage);
        this.$forceUpdate();
      } else {
        shipImage.deleted = !this.isDeleted(shipImage);
        this.$forceUpdate();
      }
    },
    getPrependIcon(shipImage) {
      if (this.isDeleted(shipImage)) {
        return 'bin'
      } else if (this.isChanged(shipImage)) {
        return 'pencil'
      } else if (this.isNew(shipImage)) {
        return 'asterisk'
      }
      return null;
    },
    saveShipImages() {
      this.shipImages.forEach((shipImage) => {
        let endpoint = '/ingame/expo/shipimage';
        let event = null;
        let method = null;
        let payload = null;
        if (this.isNew(shipImage)) {
          event = 'shipImageCreated';
          method = apiSecured.post;
          payload = {
            shipId: this.shipId,
            fileId: shipImage.fileId,
            sorting: shipImage.sorting,
          }
        } else if (this.isChanged(shipImage)) {
          endpoint = '/ingame/expo/shipimage/' + shipImage.id;
          event = 'shipImagesUpdated';
          method = apiSecured.patch;
          payload = [
            {
              op: 'replace',
              path: `/sorting`,
              value: shipImage.sorting
            },
          ];
        } else if (this.isDeleted(shipImage)) {
          endpoint = '/ingame/expo/shipimage/' + shipImage.id;
          event = 'shipImageDeleted';
          method = apiSecured.delete;
          payload = {};
        } else {
          return;
        }
        this.loading = true;
        this.inProgress++;
        method(endpoint, payload).then((res) => {
          this.showSuccessNotification(this.__('common.saved'));
          this.$emit(event);
          this.inProgress--;
          if (this.inProgress <= 0) {
            this.loading = false;
            this.inProgress = 0;
            this.refresh()
          }
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
          this.inProgress--;
          if (this.inProgress <= 0) {
            this.loading = false;
            this.inProgress = 0;
            this.refresh()
          }
        });
      });
      this.refresh();
    }
  }
}
</script>