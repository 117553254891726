<template>
  <div class="ship-tiles">
    <router-link class="ship-tiles__tile"
                 v-for="ship in ships"
                 :key="'ship_'+ship.id"
                 :style="getTileStyle(ship)"
                 :to="{name: 'ingame_ship', params: {id: ship.id}}"
    >
      <div class="ship-tiles__tile-title"><span>{{ ship.name }}</span></div>
      <div class="ship-tiles__tile-in-charge" v-if="ship.contributors.length > 0">
        <div class="ship-tiles__tile-in-charge-avatar"
             :style="{'background-image': `url(${getAvatar(ship.contributors[0].player)})`}"></div>
        <div class="ship-tiles__tile-in-charge-username">{{ ship.contributors[0].player.name }}</div>
        <div class="ship-tiles__tile-in-charge-contributors" :title="ship.contributors.map(contributor => contributor.player.name).join(', ')">+
          {{ ship.contributors.length > 0 ? ship.contributors.length - 1 : 0 }}
        </div>
      </div>
      <div class="ship-tiles__tile-likes" :title="`${ship.likes.length} likes`">
        <img src="https://cdn.hyperion-corporation.de/ui/svgs/heart-love.svg"/>
        <span>{{ ship.likes.length }}</span>
      </div>
      <div class="ship-tiles__tile-ship-class">
        <div class="ship-tiles__tile-ship-class-bg" :style="typeStyle(ship.shipClass)"></div>
        <span><span class="text--primary">{{ship.coreSize.toUpperCase()}}</span> - {{ ship.shipClass.name }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import { localizationMixin } from "../../../mixins/localization";

export default {
  name: "ShipTiles",
  mixins: [localizationMixin],
  props: {
    ships: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    states: []
  }),
  methods: {
    typeStyle(shipClass) {
      let { color } = shipClass;
      if(!color) {
        color = '#fff';
      }
      if (color) {
        return {
          'border-left': `5px solid ${color}`
        }
      }
      return {};
    },
    getTileStyle(ship) {
      if(ship.shipImages.length > 0) {
        return {
          'background-image': `url(${this.renderPoster(ship.shipImages[0])})`
        }
      }
      return {};
    },
    getAvatar(player) {
      if (player && player.avatar) {
        return player.avatar
      }
      return 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
    },
    renderPoster(shipImage) {
      if(shipImage.file.type === 'mp4') {
        return shipImage.file.thumbnailUri;
      }
      return shipImage.file.uri;
    },
  },
}
</script>