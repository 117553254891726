<template>
  <div class="view">
    <app-header title="Ingame - Expo Ship"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__buttons">
        <router-link class="control-bar__button"
                     :title="__('component.files.file_browser.navigate_up')"
                     :to="{name: 'ingame_ships'}"
                     v-slashes
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/chevron-left.svg"
               alt="back"/>
        </router-link>
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button"
             v-if="hasEditRights"
             :title="__('common.edit')" @click="editShip(ship)" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/pencil.svg"
               alt="edit"/>
        </div>
      </div>
    </div>
    <div class="main-content-padding">
      <div class="layout layout--wrap ship" v-if="ship">
        <div class="flex xs12 sm12 md4">

          <div class="ship__header">
            <div class="ship__name">{{ ship.name }}</div>
            <div class="ship__like" :class="{'ship__like--liked': userHasLiked}" v-slashes @click="toggleLike">
              <div class="ship__like-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1850 1850">
                  <g transform="matrix(1,0,0,-1,37.966102,1343.4237)">
                    <path
                        d="m 896,-128 q -26,0 -44,18 L 228,492 q -10,8 -27.5,26 Q 183,536 145,583.5 107,631 77,681 47,731 23.5,802 0,873 0,940 q 0,220 127,344 127,124 351,124 62,0 126.5,-21.5 64.5,-21.5 120,-58 55.5,-36.5 95.5,-68.5 40,-32 76,-68 36,36 76,68 40,32 95.5,68.5 55.5,36.5 120,58 64.5,21.5 126.5,21.5 224,0 351,-124 127,-124 127,-344 0,-221 -229,-450 L 940,-110 q -18,-18 -44,-18"/>
                  </g>
                </svg>
                <div class="ship__likes">{{ ship.likes.length }}</div>
              </div>
              <div class="ship__like-label">{{userHasLiked ? 'unlike' : 'like'}}</div>
            </div>
          </div>

          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6">
              <div class="tile">
                <div class="ship__label">{{ __('shipClass') }}</div>
                <div class="mt"
                     :style="{borderBottom: `2px solid ${ship.shipClass.color ? ship.shipClass.color : '#fff'}`}">
                  {{ ship.shipClass.name }}
                </div>
                <div class="bodytext" v-html="ship.shipClass.description"></div>
              </div>
            </div>
            <div class="flex xs12 sm6">
              <div class="tile">
                <div class="ship__label">{{ __('coreSize') }}</div>
                <div class="ship__core-size">{{ ship.coreSize }}</div>
              </div>
            </div>
          </div>

          <div class="layout layout--wrap mt">
            <div class="flex xs12" :class="{'sm6': ship.organization}">
              <div class="tile">
                <div class="ship__label">{{ __('entity.common.user') }}</div>
                <div class="ship__contributor">
                  <div class="ship__contributor-avatar" :style="{backgroundImage: `url(${userAvatar})`}"></div>
                  <div class="ship__contributor-name">{{ ship.user.userName }}</div>
                </div>
              </div>
            </div>
            <div class="flex xs12 sm6" v-if="ship.organization">
              <div class="tile">
                <div class="ship__label">{{ __('entity.common.organization') }}</div>
                <div class="ship__contributor">
                  <div class="ship__contributor-avatar" v-if="organizationLogoIsSvg"
                       v-html="ship.organization.logo"></div>
                  <div class="ship__contributor-avatar" v-else
                       :style="{backgroundImage: `url(${organizationLogo})`}"></div>
                  <div class="ship__contributor-name">{{ ship.organization.name }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="layout layout--wrap mt">
            <div class="flex xs12">
              <div class="tile">
                <div class="ship__label">{{ __('entity.ingame.project.contributors') }}</div>
                <div class="ship__contributors">
                  <div class="ship__contributor" v-for="contributor in sortedContributors">
                    <div class="ship__contributor-avatar"
                         :style="{backgroundImage: `url(${contributor.player.avatar})`}"></div>
                    <div class="ship__contributor-name">{{ contributor.player.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tile ship__description mt bodytext" v-html="ship.description"></div>
        </div>
        <div class="flex xs12 sm12 md8">
          <ship-swiper ref="shipSwiper"/>
          <div class="tile mt">
            <ship-comments :ship-id="ship.id" />
          </div>
        </div>
      </div>
    </div>
    <edit-ship ref="editShip" @updated="refresh" @created="editShip"/>
  </div>
</template>

<script>
import apiSecured from '../../../api/secured';
import AppHeader from '../../../components/AppHeader';
import ShipTiles from '../../../components/Ingame/Expo/ShipTiles';
import { notificationsMixin } from '../../../mixins/notifications';
import { localizationMixin } from '../../../mixins/localization';
import { pageTitleMixin } from "../../../mixins/pageTitle";
import ShipSwiper from "../../../components/Ingame/Expo/ShipSwiper";
import EditShip from "../../../components/Ingame/Expo/EditShip";
import { userMixin } from "../../../mixins/user";
import ShipComments from "../../../components/Ingame/Expo/ShipComments";

export default {
  mixins: [notificationsMixin, localizationMixin, pageTitleMixin, userMixin],
  components: {
    ShipComments,
    EditShip,
    ShipSwiper,
    AppHeader,
    ShipTiles
  },
  data: () => ({
    loading: false,
    ship: null,
    shipId: null
  }),
  watch: {
    shipId() {
      this.refresh();
    }
  },
  computed: {
    userHasLiked() {
      return this.ship.likes.some(like => like.userId === this.$store.state.user.id);
    },
    sortedContributors() {
      return this.ship.contributors.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
      })
    },
    hasEditRights() {
      if (this.hasOneRoles([])) {
        return true;
      }
      return this.ship && this.$store.state.user.id === this.ship.userId;
    },
    organizationLogoIsSvg() {
      const logo = this.ship.organization.logo;
      return logo.substr(0, '<svg'.length).toLowerCase() === '<svg';
    },
    organizationLogo() {
      const logo = this.ship.organization.logo;
      if (logo.substr(0, 5).toLowerCase() === 'https') {
        return logo;
      } else if (logo.substr(0, 'assets.prod.novaquark.com'.length).toLowerCase() === 'assets.prod.novaquark.com') {
        return `https://${logo}`;
      }
      return logo;
    },
    userAvatar() {
      if (this.ship.user && this.ship.user.avatar) {
        return this.ship.user.avatar;
      }
      return 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
    }
  },
  methods: {
    addShip() {
      this.$refs.editShip.showCreate();
    },
    editShip(ship) {
      this.$refs.editShip.showEdit(ship);
    },
    refresh() {
      this.loading = true;
      apiSecured.get(`/ingame/expo/ship/${this.shipId}`).then(async (res) => {
        this.ship = await this.enrichRecords(res.data);
        this.$nextTick(() => this.$refs.shipSwiper.initSwiper(this.ship.shipImages));
        this.setPageTitle(this.ship.name + ' - Expo Ships');
        this.loading = false;
        this.showInfoNotification('Ship loaded');
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    async enrichRecords(ship) {
      let shipImages = [];

      await apiSecured.get(`/community/user/${ship.userId}`).then((res) => {
        ship.user = res.data
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      if (ship.organizationId) {
        await apiSecured.get(`/ingame/civilization/organization/${ship.organizationId}`).then((res) => {
          ship.organization = res.data
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
      }

      await apiSecured.get(`/ingame/expo/shipclass/${ship.shipClassId}`, {
        params: {
          fields: 'id,name,color'
        },
      }).then((res) => {
        ship.shipClass = res.data;
        this.showInfoNotification('Ship class has been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const params = {
        orderBy: 'sorting asc'
      }
      await apiSecured.get(`/ingame/expo/shipimage/by-ship/${ship.id}`, { params }).then((res) => {
        shipImages = res.data
        this.showInfoNotification('Ship images have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const fileIds = shipImages.map(shipImage => shipImage.fileId);
      if (fileIds.length > 0) {
        await apiSecured.get(`/fal/file/(${fileIds.join(',')})`).then((res) => {
          shipImages = shipImages.map(shipImage => {
            shipImage.file = res.data.find(file => file.id === shipImage.fileId);
            return shipImage;
          });
          this.showInfoNotification('Files have been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
        ship.shipImages = shipImages;
      }

      return ship;
    },
    toggleLike() {
      if(this.loading) {
        return;
      }
      this.loading = true;
      let method = apiSecured.post;
      if(this.userHasLiked) {
        method = apiSecured.delete;
      }
      method(`/ingame/expo/shiplike/${this.shipId}`).then(async (res) => {
        this.loading = false;
        this.showLoveNotification(this.__(this.userHasLiked ? 'brokenHeart' : 'thxForLove'));
        this.refresh();
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
  },
  created() {
    this.shipId = this.$route.params.id;
  }
}
</script>
