<template>
  <div class="edit-ship">
    <my-dialog :show="show" @close="close">
      <div class="card">
        <div class="card__header">
          <div class="card__title">Ship registration</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12 sm12 md6">
              <div class="layout layout--wrap">
                <div class="flex xs12">
                  <div class="tile">
                    <div class="bodytext">
                      <h2>Registration rules</h2>
                      <ul class="futuristic">
                        <li>Every ship registration is public and visible for everybody.</li>
                        <li>Each user may only exhibit one ship per area.</li>
                        <li>If more ships per user and ship class are registered, the additional registrations will be used to fill free exhibition areas.
                        </li>
                        <li>The Alioth Aerospace Expo Committee can reject registrations if they don't fit the registered ship class or core size.</li>
                        <li><span class="text--primary">The area " Organizations":</span> Every organization with more than ten registered ships can get its own L core for exhibition space on request. Please contact <span class="text--teal">Underhand Aerial</span> directly for this.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.name"
                      :label="__('entity.common.name')"
                      :error-messages="errors.name"
                      maxlength="50"
                      :prepend-icon="isChanged('name') ? 'pencil' : null"
                      @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('shipClass') }}</label>
                  <select-reference
                      v-model="record.shipClassId"
                      :endpoint="'/ingame/expo/shipclass'"
                      :field-description="'description'"
                      :field-color="'color'"
                      :nothing-selected-message="__('noShipClassSelected')"
                      :prepend-icon="isChanged('shipClassId') ? 'pencil' : ''"
                      @input="registerChange('shipClassId')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('organization') }} (optional)</label>
                  <select-reference
                      v-model="record.organizationId"
                      :endpoint="'/ingame/civilization/organization'"
                      :nothing-selected-message="__('noOrganizationSelected')"
                      :prepend-icon="isChanged('organizationId') ? 'pencil' : ''"
                      @input="registerChange('organizationId')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('coreSize') }}</label>
                  <single-select
                      :items="coreSizes"
                      v-model="record.coreSize"
                      :append-icon="isChanged('coreSize') ? 'pencil' : null"
                      @input="registerChange('coreSize')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <label>{{ __('entity.ingame.project.contributors') }}</label>
                  <p>Add everybody who participated into this construct and the creator first.</p>
                  <multi-select
                      v-model="record.contributorsPlayerIds"
                      :endpoint="'/ingame/civilization/player'"
                      :field-title="'name'"
                      :field-icon="'avatar'"
                      :field-icon-default="'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png'"
                      :prepend-icon="isChanged('contributorsPlayerIds') ? 'pencil' : ''"
                      @input="registerChange('contributorsPlayerIds')"
                  />
                </div>
                <div class="flex xs12 mt bodytext">
                  <label>{{ __('entity.common.description') }}</label>
                  <ckeditor v-model="record.description"
                            :editor="editor"
                            :config="editorConfig"
                            @input="registerChange('description')"></ckeditor>
                </div>
                <div class="flex xs12">
                  <div class="layout">
                    <div class="flex xs6 mt">
                      <div class="btn btn--success" :loading="loading"
                           :disabled="changedProperties.length === 0"
                           @click="update" v-slashes>{{ __('common.save_changes') }}
                      </div>
                    </div>
                    <div class="flex xs6 mt">
                      <div class="btn btn--primary" @click="close" v-slashes>{{
                          __('common.close')
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs12 sm12 md6">
              <edit-ship-images ref="editShipImages" :ship-id="record.id"/>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '../../../mixins/localization';
import { editFormMixin } from "../../../mixins/editForm";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditShipImages from "./EditShipImages";

const defaultDescription = `
<h2>This is an example description. Change it as you want it. You can format your text by clicking on the 3 dots on the white bar.</h2>
<p>A short description about the ship like design ideas, areas of application or the story behind it</p>

<h2>Length</h2>
<p>100m</p>

<h2>Width</h2>
<p>100m</p>

<h2>Height</h2>
<p>100m</p>

<h2>Empty Mass</h2>
<p>2000 tons</p>

<h2>Lifting capabilities</h2>
<p>500 kn</p>
<p>5 tons</p>

<h2>Building time</h2>
<p>2 month</p>

<h2>Count of voxel materials</h2>
<p>10</p>

<h2>Price</h2>
<p>2,000,000 quanta</p>
`

export default {
  components: { EditShipImages },
  mixins: [localizationMixin, editFormMixin],
  data: () => ({
    endpoint: '/ingame/expo/ship',
    emptyRecord: {
      id: null,
      name: '',
      description: defaultDescription,
      coreSize: null,
      shipClassId: null,
      organizationId: null,
      userId: null,
      contributors: [],
      contributorsPlayerIds: [],
      createdAt: '---',
      updatedAt: '---'
    },
    coreSizes: [],
    editor: ClassicEditor,
    editorConfig: {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      }
    }
  }),
  methods: {
    postProcess() {
      this.record.contributors = this.record.contributors.sort((a,b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
      });
      this.record.contributorsPlayerIds = this.record.contributors.map(contributor => {
        return contributor.playerId;
      });
    },
  },
  created() {
    this.coreSizes = [
      { value: 'xs', title: 'XS' },
      { value: 's', title: 'S' },
      { value: 'm', title: 'M' },
      { value: 'l', title: 'L' },
    ];
  }
}
</script>