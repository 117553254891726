<template>
  <div class="ship-comments">
    <div class="ship-comments__new">
      <h2>Write a comment</h2>
      <ckeditor v-model="commentBodytext"
                :editor="editor"
                :config="editorConfig"
      ></ckeditor>
      <div class="btn btn--success mt" :disabled="commentBodytext.length < minLength || newCommentLockedSecondsLeft > 0"
           style="max-width: 300px"
           @click="addComment"
           :loading="loading"
      >
        Submit&nbsp;<span v-if="newCommentLockedSecondsLeft > 0">({{ newCommentLockedSecondsLeft }})</span>
      </div>
    </div>
    <pagination
        class="mt"
        :count-pages="pagination.pages"
        :total-visible="10"
        :page="pagination.page"
        :disabled="loading"
        @paginate="paginate"
    />
    <div class="ship-comment mt" v-for="shipComment in shipComments">
      <div class="ship-comment__header">
        <div class="ship-comment__user">
          <div class="ship-comment__user-avatar"
               :style="{'background-image': `url(${getAvatar(shipComment.user)})`}"></div>
          <div class="ship-comment__user-name">{{ shipComment.user.userName }}</div>
        </div>
        <div class="ship-comment__created-at" v-html="renderDatetime(shipComment.createdAt)"></div>
      </div>
      <div class="ship-comment__bodytext bodytext" v-html="shipComment.bodytext"></div>
    </div>
    <pagination
        class="mt"
        :count-pages="pagination.pages"
        :total-visible="10"
        :page="pagination.page"
        :disabled="loading"
        @paginate="paginate"
    />
  </div>
</template>

<script>

import { paginationMixin } from "../../../mixins/pagination";
import { localizationMixin } from "../../../mixins/localization";
import { notificationsMixin } from "../../../mixins/notifications";
import apiSecured from "../../../api/secured";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "ShipComments",
  mixins: [paginationMixin, localizationMixin, notificationsMixin],
  props: ['shipId'],
  data: () => ({
    loading: false,
    shipComments: [],
    skipCreated: true,
    commentBodytext: '',
    newCommentLockedInterval: undefined,
    newCommentLockedSecondsLeft: 0,
    minLength: 10,
    editor: ClassicEditor,
    editorConfig: {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      }
    }
  }),
  watch: {
    shipId() {
      this.refresh();
    }
  },
  methods: {
    renderDatetime(value) {
      if (value !== null) {
        let datetime = new Date(value);
        const datestring = `${datetime.getFullYear()}-${(datetime.getMonth() + 1).toString().padStart(2, '0')}-${datetime.getDate().toString().padStart(2, '0')}`;
        const timestring = `${datetime.getHours().toString().padStart(2, '0')}:${datetime.getMinutes().toString().padStart(2, '0')}:${datetime.getSeconds().toString().padStart(2, '0')}`;
        const fDatestring = `<span class="text--teal">${datestring}</span>`;
        const fTimestring = `<span class="text--cyan">${timestring}</span>`;
        return `${fDatestring} ${fTimestring}`;
      }
      return '-';
    },
    getAvatar(user) {
      if (user && user.avatar) {
        return user.avatar
      }
      return 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
    },
    setNewCommentLockInterval() {
      this.newCommentLockedSecondsLeft = 10;
      this.newCommentLockedInterval = setInterval(() => {
        this.newCommentLockedSecondsLeft--;
        if(this.newCommentLockedSecondsLeft === 0) {
          clearInterval(this.newCommentLockedInterval);
          this.newCommentLockedInterval = undefined;
        }
      }, 1000);
    },
    refresh() {
      this.loading = true;
      let params = this.getPaginationParams();
      params.pageSize = 5;
      apiSecured.get(`/ingame/expo/shipcomment/by-ship/${this.shipId}`, { params }).then(async (res) => {
        this.shipComments = res.data;
        this.updatePagination(res);
        this.showInfoNotification('Comments loaded');
        this.setNewCommentLockInterval();
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    addComment() {
      if (this.commentBodytext < this.minLength || this.newCommentLockedSecondsLeft > 0 || this.loading) {
        return;
      }
      this.loading = true;
      let payload = {
        shipId: this.shipId,
        bodytext: this.commentBodytext
      };
      apiSecured.post('/ingame/expo/shipcomment', payload).then(async (res) => {
        this.loading = false;
        this.commentBodytext = '';
        this.showSuccessNotification('Comment created');
        this.refresh();
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
  },
  created() {
    this.refresh();
  }
}
</script>